import { ChangeEvent, FC } from "react";
import { BeatLoader, FadeLoader, PuffLoader, ScaleLoader } from "react-spinners";
import RingLoader from "react-spinners/RingLoader";


export function readFileAsDataURL(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(event.target?.result as string);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}


interface Props {
  row?: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  height?: string;
  className?: string;
  id: string;
  name: string;
  accept?: string;
  maxFileSize?: number;
  width?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  validate?: (files: FileList) => Promise<boolean>;
  loading?: boolean;
}

const FileUpload: FC<Props> = ({
  row, children, icon, height, className, name,
  id, accept, width, onChange, validate, loading
}) => {
  return (
    <div>
      <label
        className={`flex ${row || "flex-col-reverse"
          } justify-center text-center bg-xd_bleach items-center outline-dashed ${height || "h-full"
          } ${width ? width : "w-[380px]"} rounded-md outline-xd_light_grey cursor-pointer ${className || ""}`}
      >
        <div className="py-2">
        {loading ? <ScaleLoader color="#ffbd59" /> :
          <div className="flex flex-col-reverse items-center">
            <div className="max-w-sm">{children}</div>
            {typeof children === "string" ? icon : ""}
          </div>
        }
        </div>
        {/* TODO: make the icon appear on top of the image (children) */}
        <input
          type="file"
          name={name}
          id={id}
          className="hidden"
          accept={accept}
          disabled={loading}
          onChange={(e) => {
            const files = e.target.files ? e.target.files : new FileList();
            if (validate) {
              validate(files).then((res) => {
                //console.log({ res })
                if (res && onChange) onChange(e);
              }).catch(err => {
                console.log(err);
              });
            } else if (onChange) {
              onChange(e);
            }
          }}
        />
      </label>
    </div>
  );
};

export default FileUpload;
