import IEarning from '../../../interfaces/earnings';
import formatAmount from '../../../utils/money';
import InboxIcon from '../../Icons/InboxIcon'

interface Props {
  earning: IEarning;
}

function MonthlyRecords({ earning }: Props) {
  return (
    <div>
      <div className='bg-white shadow-lg justify-between md:p-8 p-5 rounded-[10px] items-center flex'>
        <div>
          <div>{Intl.DateTimeFormat('en', { month: 'long' }).format(new Date(earning.year, earning.month-1))}</div>
          <div className='my-3'>{formatAmount(earning.total_amount, earning.currency)}</div>
        </div>
        <div className='w-[16px] h-[16px] flex-shrink-0'>
          <InboxIcon />
        </div>
      </div>
    </div>

  )
}
;
export default MonthlyRecords
