import { useFormik } from "formik";
import { toast } from "react-toastify";
import sendRequest from "../axios";
import Button from "../Button/Button";
import { useDashboardContext } from "../Dashboard/DashboardContext";
import Input from "../Input/Index";
import * as Yup from "yup";
import { useState } from "react";
import { PuffLoader } from "react-spinners";

const ArtistSchema = Yup.object().shape({
  name: Yup.string().trim().required("name is required"),
  spotify_url: Yup.string().trim().url("spotify url must be a url in the format https://spotify.com"),
  apple_url: Yup.string().trim().url("apple url must be a url in the format https://apple.com"),
});

function CreateNewArtist({ onSuccess }: { onSuccess?: () => void }) {
  const { getArtists } = useDashboardContext();
  const [loading, setLoading] = useState(false);

  const { handleSubmit, handleChange, errors } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        name: "",
        spotify_url: "",
        apple_url: "",
      },
      validationSchema: ArtistSchema,
      onSubmit: (values) => {
        setLoading(true);
        sendRequest("POST", "/api/v1/artists", values)
          .then((res) => {
            toast.success(res.data.message);
            getArtists();
            onSuccess && onSuccess();
          })
          .catch((err) => {
            //console.log({ err });
            toast.error(err.response.data.errors);
          }).finally(() => setLoading(false));
        //resetForm();
      },
    });
  return (
    <div className="space-y-4">
      <div>
        <h1 className="text-2xl font-semibold">Create New Artist</h1>
      </div>
      <form className=" space-y-5" onSubmit={handleSubmit}>
        <div className="">
          <Input
            label="Artist Name"
            type="text"
            id="name"
            name="name"
            placeholder="Enter Artist Name"
            onChange={handleChange}
          />
          {errors.name ?
            <p className="text-xd_red font-normal text-sm">{errors.name}</p>
            : ""}
        </div>
        <div className="">
          <Input
            label="Spotify URL"
            type="text"
            id="spotify_url"
            name="spotify_url"
            placeholder="Enter spotify url"
            onChange={handleChange}
          />
          {errors.spotify_url ?
            <p className="text-xd_red font-normal text-sm">{errors.spotify_url}</p>
            : ""}
        </div>
        <div className="">
          <Input
            label="Apple URL"
            type="text"
            id="apple_url"
            name="apple_url"
            placeholder="Enter spotify url"
            onChange={handleChange}
          />
          {errors.apple_url ?
            <p className="text-xd_red font-normal text-sm">{errors.apple_url}</p>
            : ""}
        </div>
        <div className="flex lg:justify-end justify-center my-3">
          <Button type="submit">{loading ?
            <PuffLoader className="h-4 w-4" size={30} color="white" />
            : "Create Artist"}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default CreateNewArtist;
