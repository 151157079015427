import { useState } from 'react'
import { useDashboardContext } from '../../Dashboard/DashboardContext';
import MonthlyRecords from './MonthlyRecords';
import ShowYear from './ShowYear'

function Earnings() {
  const { earnings } = useDashboardContext();
  const [toggleview, setToggleview] = useState(false);

  function handleClick() {
    setToggleview((toggleview) => !toggleview);
  }

  return (
    <div>
      <ShowYear year={earnings.length ? `${earnings[0].year}` : `${new Date().getFullYear()}`} toggleview={toggleview} changeView={handleClick} />
      <div className={`grid-cols-2 gap-3 lg:grid-cols-3 md:gap-6 lg:gap-12 items-start my-5 ${toggleview ? "hidden" : "grid"}`}>
        {earnings.map((data, i) => {
          return (
            <div key={i}>
              <MonthlyRecords earning={data} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Earnings
