import { createContext, useContext, useEffect, useState } from "react";
import IRelease, { Track } from "../../interfaces/release";
import sendRequest from "../axios";
import { IArtist } from "../../interfaces/artist";
import IUser from "../../interfaces/user";
import ISubscriptionPlan from "../../interfaces/subplan";
import IPlaylistRequest from "../../interfaces/playlist_request";
import { Navigate } from "react-router-dom";
import { BarLoader, PuffLoader } from "react-spinners";
import IEarning from "../../interfaces/earnings";

interface IContextData {
  releases: IRelease[];
  getReleases: () => void;
  artists: IArtist[];
  user: IUser;
  tracks: Track[];
  getTracks: () => void;
  plans: ISubscriptionPlan[];
  currentPlan?: ISubscriptionPlan;
  getArtists: () => void;
  artistMap: { [index: string]: boolean };
  playlistRequests: IPlaylistRequest[];
  fetchPlaylistRequests: () => void;
  balance: number;
  earnings: IEarning[];
}

export const DashboardContext = createContext({} as IContextData);

export const useDashboardContext = () => useContext(DashboardContext);

interface Props {
  children: React.ReactNode;
}

export default function DashboardProvider({ children }: Props) {
  const [user, setUser] = useState<IUser | undefined>();
  const [playlistRequests, setPlaylistRequests] = useState<IPlaylistRequest[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [releases, setReleases] = useState<IRelease[]>([]);
  const [artists, setArtists] = useState<IArtist[]>([]);
  const [tracks, setTracks] = useState<Track[]>([]);
  const [plans, setPlans] = useState<ISubscriptionPlan[]>([]);
  const [currentPlan, setCurrentPlan] = useState<ISubscriptionPlan>();
  const [artistMap, setArtistMap] = useState<{ [index: string]: boolean }>({});
  const [earnings, setEarnings] = useState<IEarning[]>([]);
  const [balance, setBalance] = useState<number>(0);

  function getEarnings() {
    sendRequest("GET", "/api/v1/earnings", null).then(res => {
      const data = res.data.data;
      setEarnings(data.earnings);
      setBalance(data.balance);
    }).catch(err => {
      console.log(err);
    })
  }

  useEffect(() => {
    if (user && user.subscription && plans && plans.length) {
      setCurrentPlan(
        plans.find((p) => p.id === user.subscription.subscription_plan_id || p.name === user.subscription.plan)
      );
    }
  }, [user, plans]);

  const getPlans = () => {
    let currency = "USD";
    if (user?.country === "NG") {// TODO: change this to NG
      currency = "NGN";
    }
    sendRequest("GET", `/api/v1/subscriptionplans?currency=${currency}`, null).then((res) => {
      setPlans(res.data.data);
    });
  };

  function getTracks() {
    sendRequest("GET", "/api/v1/tracks", null)
      .then((res) => {
        // console.log(res.data.data);
        setTracks(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getReleases() {
    sendRequest("GET", "/api/v1/release", null)
      .then((res) => {
        setReleases(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getArtists() {
    sendRequest("get", "/api/v1/artists", {})
      .then((res) => {
        setArtists(res.data.data);
      })
      .catch((err) => {
        console.log({ err });
      });
  }

  function getLoggedInUser(): Promise<void> {
    setLoading(true);
    return sendRequest("GET", "/api/v1/me", {}).then((res) => {
      //console.log({user:res.data.data});
      // console.log({res});
      setUser(res.data.data);
    }).catch(err => {
      console.log({ err })
    }).finally(() => {
      setLoading(false);
    });
  }

  function fetchPlaylistRequests() {
    sendRequest("GET", `/api/v1/playlist-support`, null).then((response) => {
      setPlaylistRequests(response.data.data);
    });
  }

  useEffect(() => {
    setArtistMap(
      artists.reduce((acc, artist) => {
        acc[artist.id] = true;
        return acc;
      }, {} as { [index: string]: boolean })
    );
  }, [artists]);

  useEffect(() => {
    getLoggedInUser();
  }, []);

  useEffect(() => {
    if (user) {
      getTracks();
      getReleases();
      getArtists();
      getPlans();
      fetchPlaylistRequests();
      getEarnings();
    }
  }, [user]);
  return loading ?
    <div className="h-screen w-full justify-center flex flex-col items-center">
      <PuffLoader color="orange" />
    </div>
    :
    user ? (
      <DashboardContext.Provider
        value={{
          currentPlan,
          plans,
          tracks,
          user,
          releases,
          artists,
          getArtists,
          artistMap,
          playlistRequests,
          fetchPlaylistRequests,
          getReleases,
          getTracks,
          balance, earnings
        }}>
        {children}
      </DashboardContext.Provider>
    ) : <Navigate to="/login" />;
}
