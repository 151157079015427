import moment from "moment";
import { useState } from "react";
import CreateNewArtist from "../../components/artist/create_artist_form";
import Button from "../../components/Button/Button";
import { useDashboardContext } from "../../components/Dashboard/DashboardContext";
import Header from "../../components/Header/Header";
import Modal from "../../components/modal";
import Table from "../../components/Table/Table";
import { IArtist } from "../../interfaces/artist";

function GetArtistTableData(artists: IArtist[]) {
  return artists.map(a => ({
    name: a.name,
    created_at: moment.unix(a.created_at).format("LL"),
    spotify_url: a.spotify_url,
    apple_url: a.apple_url
  }));
}

function Artists() {
  const { artists } = useDashboardContext();
  const [showNewArtistForm, setShowNewArtistForm] = useState(false);
  const columns = [
    { title: "Name", dataIndex: "name", key: "1" },
    { title: "Date Added", dataIndex: "created_at", key: "2" },
    { title: "Spotify", dataIndex: "spotify_url", key: "3" },
    { title: "Apple", dataIndex: "apple_url", key: "3" },
  ];
  return (
    <div className="space-y-8">
      <div>
        <Header children={"Artists"} />
      </div>
      <Modal
        closeIconColor="black"
        className="md:w-[60%] w-full md:p-10 p-2"
        open={showNewArtistForm}
        openModal={() => setShowNewArtistForm(true)}
        closeModal={() => setShowNewArtistForm(false)}>
        <div className=" mx-auto">
          <CreateNewArtist onSuccess={() => setShowNewArtistForm(false)} />
        </div>
      </Modal>
      <div className="md:bg-white bg-transparent rounded-[10px] items-center flex-1 space-y-5">
        <div className="p-4">
          <Button textColor="text-white" onClick={() => setShowNewArtistForm(true)}>+ Add Artist</Button>
        </div>
        <div className="overflow-scroll">
          <Table data={GetArtistTableData(artists)} columns={columns} />
        </div>
      </div>
    </div>
  );
}

export default Artists;
