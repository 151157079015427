import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";
import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import IRelease from "../../interfaces/release";
import sendRequest from "../axios";
import { useDashboardContext } from "../Dashboard/DashboardContext";
import InfoIcon from "../Icons/InfoIcon";

interface ReleaseAction {
  route?: string;
  text: string;
  action?: React.MouseEventHandler;
}
interface Props {
  body: Array<ReleaseAction>;
}
interface ReleaseActionProps {
  release: IRelease;
  showModal: () => void;
}

// function classNames(...classes: any[]) {
//   return classes.filter(Boolean).join(' ')
// }

export function ReleaseAction({ release, showModal }: ReleaseActionProps) {
  const { getReleases, getTracks } = useDashboardContext();
  const body: Array<ReleaseAction> = [
    {
      text: "View",
      route: `/dashboard/my-music/${release.id}`,
    },
    ...release.status !== "draft" && release.status !== 'rejected' ? [] : [{
      text: "Edit",
      action: showModal,
    },
    {
      text: "Submit",
      action: () => {
        if (release.status !== "draft" && release.status !== 'rejected') {
          toast.error("Release is not in draft or rejected mode");
          return;
        }
        sendRequest("PUT", `/api/v1/release/${release.id}/status/submitted`, null)
          .then((res) => {
            // console.log(res);
            getReleases();
            getTracks();
          })
          .catch((err) => {
            // console.error(err);
            if (err?.response?.data?.errors) {
              toast.error(err.response.data.errors);
              return;
            }
            toast.error("Failed to submit");
          });
      },
    }]
  ];

  return Action({ body });
}

function Action({ body }: Props) {

  return (
    <Menu as="div" className="relative inline-block text-left" >
      <MenuButton className="flex justify-center items-start w-5 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-xd_yellow">
        <span className="sr-only">Open options</span>
        <InfoIcon />
      </MenuButton>

      <MenuItems transition anchor="bottom"
        className="mt-2 w-28 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <div className="w-full">
          {body.map((value, i) => {
            return <MenuItem key={i}>
              {value.action ?
                <div onClick={value.action} className={'hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-2 py-2 text-sm cursor-pointer'}>
                  {value.text}
                </div> :
                <Link to={`${value.route || ''}`} className={'hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-2 py-2 text-sm cursor-pointer'}>
                  {value.text}
                </Link>}
            </MenuItem>
          })}
        </div>
      </MenuItems>
    </Menu>
  )
}

export default Action;
